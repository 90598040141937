<template>
  <!-- Table Container Card -->
  <div>
    <add-new-permission
      :is-add-new-permission-sidebar-active.sync="
        isAddNewPermissionSidebarActive
      "
      @clickaddNewPermission="clickaddNewPermission"
    />
    <add-edit-permission
      :is-add-edit-permission-sidebar-active.sync="
        isAddEditPermissionSidebarActive
      "
      :editPermissionData="editPermissionData"
      @clickEditNewPermission="clickEditNewPermission"
    />
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <b-breadcrumb :items="items" /> -->

            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6" v-if="addpermissionHideButton == true">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                @input="permissionFilter"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewPermissionSidebarActive = true"
              >
                <span class="text-nowrap">Add Permission</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <span v-for="level_data in table_data" :key="level_data.Level">
        {{level_data}}
      </span> -->
      <b-overlay :show="show" rounded="sm">
        <b-table-simple
          v-if="addpermissionHideButton == true"
          hover
          caption-top
          responsive
          class="rounded-bottom mb-0"
        >
          <b-thead head-variant="light">
            <b-tr>
              <b-th>No.</b-th>
              <b-th>Permission Name</b-th>
              <b-th>Role Name</b-th>
              <b-th>Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(permission_data, index) in table_data">
              <b-tr :key="index">
                <b-td>
                  {{ index + 1 }}
                </b-td>
                <b-td>
                  {{ permission_data.name }}
                </b-td>
                <b-td>
                  <span
                    v-for="(roleName, index) in permission_data.roleName"
                    :key="index"
                  >
                    <b-badge pill variant="primary">
                      {{ roleName }}
                      <!-- <feather-icon
                        style="margin-left: 10px"
                        @click="delete_permission_from_role(role_data._id, index)"
                        icon="TrashIcon"
                      /> -->
                    </b-badge>

                    <br /> </span
                ></b-td>
                <!-- <b-td> </b-td>
                <b-td> </b-td> -->
                <!-- <b-td> </b-td> -->
                <!-- <b-td>
                  <b-button
                    variant="primary"
                    @click="
                      OpenEditPermissionSideBar(
                        permission_data._id,
                        permission_data.name
                      )
                    "
                  >
                    <span class="text-nowrap">Edit</span>
                  </b-button>
                </b-td> -->
                <!-- <b-td>
                  <b-button
                    variant="danger"
                    @click="deletePermission(permission_data._id)"
                  >
                    <span class="text-nowrap">Delete</span>
                  </b-button>
                </b-td> -->
                <b-td>
                  <b-dropdown variant="link" no-caret>
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="
                        OpenEditPermissionSideBar(
                          permission_data._id,
                          permission_data.name
                        )
                      "
                    >
                      <feather-icon
                        height="20px"
                        width="20px"
                        icon="EditIcon"
                      />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="deletePermission(permission_data._id)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>

                    <!-- <b-dropdown-item @click="deleterRole(role_data._id)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </b-td>
              </b-tr>
            </template>

            <!-- <b-tr> -->
          </b-tbody>
        </b-table-simple>
      </b-overlay>
      <!-- {{ this.table_data.level }} -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BOverlay,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { BBreadcrumb } from "bootstrap-vue";
import AddNewPermission from "./app/list/users-list/AddNewPermission.vue";
import AddEditPermission from "./app/list/users-list/AddEditPermission.vue";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    AddNewPermission,
    AddEditPermission,
    BBreadcrumb,
    BCard,
    BRow,
    ToastificationContent,
    BCol,
    BOverlay,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      table_data: {},
      isAddEditRoleSidebarActive: false,
      isAddNewPermissionSidebarActive: false,
      isAddEditPermissionSidebarActive: false,
      show: false,
      searchQuery: "",
      table_: [],
      editPermissionData: {
        id: "",
        name: "",
      },
      addpermissionHideButton: false,
    };
  },
  created() {
    this.gettabledata();
  },
  methods: {
    permissionFilter() {
      this.show = true;
      setTimeout(() => {
        if (this.searchQuery.trim().length > 0) {
          // console.log('this.searchQuery',this.searchQuery)
          let finaldata = this.table_.filter((item) => {
            // console.log(item.title);
            if (item.name) {
              // console.log()
              return this.searchQuery
                .toLowerCase()
                .split(" ")
                .every((v) => item.name.toLowerCase().includes(v));
            }
          });
          this.table_data = finaldata;
          this.show = false;
        } else {
          this.table_data = this.table_;
          this.show = false;
        }
      }, 1000);
    },
    deletePermission(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
          var deleteId = {
            id: "",
          };
          deleteId.id = id;
          axios
            .post(
              process.env.VUE_APP_API_URL + "/permission/delete",
              deleteId,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("_t"),
                },
              }
            )
            .then((response) => {
              // console.log(response);
              if (response.data == "Successfully Delete") {
                this.removePermssion("success", "top-left");
              } else {
                this.removePermsissionreject("danger", "top-left");
              }

              this.gettabledata();
            });
        }
      });
    },
    removePermssion(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Permsission Deleted",
          variant,
        },
      });
    },
    removePermsissionreject(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Permsission not delete",
          variant,
        },
      });
    },
    //  {},
    addDeleteRole(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Permission Successfully Delete",
          variant,
        },
      });
    },
    addDeleteRoleReject(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Permission not delete.Please try again",
          variant,
        },
      });
    },
    clickEditNewPermission(EditPermission) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/permission/edit",
          EditPermission,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data != "duplicate permission") {
            this.addEditPermission("success", "top-left");
          } else {
            this.addEditPermissionReject("danger", "top-left");
          }

          this.gettabledata();
        });
    },
    addEditPermission(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Permission Successfully Update",
          variant,
        },
      });
    },
    addEditPermissionReject(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Duplicayte Permission please try another one",
          variant,
        },
      });
    },
    OpenEditPermissionSideBar(id, name) {
      this.isAddEditPermissionSidebarActive = true;
      this.editPermissionData.id = id;
      this.editPermissionData.name = name;
      // console.log(id);
      // console.log(name);
    },
    clickaddNewPermission(add_permission_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/permission/add",
          add_permission_data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          if (response.data != "duplicate Permission") {
            this.addNewPermissionSuccess("success", "top-left");
          } else {
            this.addNewPermissionReject("danger", "top-left");
          }

          this.gettabledata();
        });
    },
    addNewPermissionReject(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Duplicate Permission please add with another name",
          variant,
        },
      });
    },
    addNewPermissionSuccess(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Permission Successfully Added",
          variant,
        },
      });
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/permission/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          // console.log("get_response.data.length",get_response.data.length)
          if (get_response.data.length == 0) {
            this.addpermissionHideButton = false;
          } else {
            this.addpermissionHideButton = true;
            this.table_ = get_response.data;
            this.table_data = get_response.data;
          }

          // console.log(get_response.data);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
